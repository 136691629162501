
export enum FirstActionType {
  ADD_FIRST = 'add_first',
  CLEAR_FIRST = 'clear_first'
}

export enum SecondActionType {
  ADD_SECOND = 'add_second',
  CLEAR_SECOND = 'clear_second'
}

export enum ThirdActionType {
  ADD_THIRD = 'add_third',
  CLEAR_THIRD = 'clear_third'
}

export enum FourthActionType {
  ADD_FOURTH = 'add_fourth',
  CLEAR_FOURTH = 'clear_fouth'
}